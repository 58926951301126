<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <v-dialog v-model="dialog" width="250">
        <v-card>
            <v-card-title class="text-purple" style="font-weight: 300; font-size: 16px">
                {{ selected_row.provider_name }}
                <!-- {{ selected_row.booking_rec_id}} -->
            </v-card-title>
            <v-card-text>
                <v-list-item three-line>
                    <!-- {{currentDate()}}
                {{new Date(selected_row.booking_date)}} -->
                    <v-list-item-content>
                        <!-- <v-list-item-title><b>User id : </b> &nbsp;{{ selected_row.user_id }}</v-list-item-title> -->
                        <v-list-item-title><b>ชื่อ : </b> &nbsp; {{ selected_row.patient_name }}</v-list-item-title>
                        <v-list-item-title><b>สถานะไลน์ : </b> &nbsp;{{ selected_row.line_status ? 'มีไลน์' : 'ไม่มีไลน์' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
            <v-card-actions style="    justify-content: space-between;">
                <v-btn @click="cf_dialog = true" class="ma-2" x-small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                &nbsp;
                <v-btn color="bg-purple text-white" @click="dialog = false">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="cf_dialog" width="300">
        <v-card>
            <v-card-text style="text-align:center">
                <br>
                <v-btn class="ma-2" small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                <br>
                <span>ยืนยันการลบผู้ป่วยจากการดูแล</span>

                <br>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">
                <v-spacer></v-spacer>
                <v-btn small outlined style="color:purple" @click="cf_dialog = false">ยกเลิก</v-btn>
                <v-spacer></v-spacer>
                <v-btn small color="bg-purple text-white" @click="delRelation">ตกลง</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-0 pt-2">
            <v-col lg="3" sm="8" class="mx-auto">
                <checkmong-home-bar title="รายชื่อผู้ป่วยในการดูแล" style="padding-top: 0px !important;"></checkmong-home-bar>
                <v-row class="d-flex pt-2">
                    <v-col cols="12" style="text-align: center;padding-bottom: 0px;">
                        <v-card>
                            <span class="text-red" style="font-size: 14px;">หมายเหตุ : ข้อมูลบันทึก AZQ จะเชื่อมต่อกับระบบผู้ป่วย<br>จากการสแกน QR เท่านั้น</span>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="text-align: right;padding-bottom: 0px;">
                        <v-btn @click="$router.push('/scan_add_patient')" tile color="bg-orange" small class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                            <v-icon left>
                                person_add_alt_1
                            </v-icon>
                            เพิ่มผู้ป่วยในการดูแล
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <v-row class="d-flex">
                    <v-col cols="7" style="padding-bottom: 0px;"> <span style="font-size: 14px;">คนไข้ทั้งหมด <b>{{patient_list.length}}</b> คน</span></v-col>
                    <v-col cols="5">
                        <v-row>
                            <v-col cols="12">
                                <v-icon small color="green">noise_control_off</v-icon><span style="font-size:11px;">มีไลน์</span>
                                &nbsp;
                                <v-icon small color="grey">noise_control_off</v-icon><span style="font-size:11px;">ไม่มีไลน์</span>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="padding-top: 0px !important;">
                        <v-card elevation="1">
                            <v-text-field v-model="search" append-icon="search" label="" single-line hide-details style="padding-top: 0px;margin-top: 0px;background-color: white;border-top-left-radius: 5px;border-top-right-radius: 5px;" flat></v-text-field>
                            <!-- <v-data-table :search="search" :disable-pagination="true" :headers="headers" :items="patient_list" :items-per-page="10" dense mobile-breakpoint @click:row="handleClick"> -->
                            <v-data-table hide-default-header hide-default-footer :search="search" :disable-pagination="false" :headers="headers" :items="patient_list" :items-per-page="itemsPerPage" mobile-breakpoint :page.sync="page" @page-count="pageCount = $event">

                                <template v-slot:item.line_status="{ item }">
                                    <div v-if="item.line_status">
                                        <v-row class="d-flex">
                                            <v-icon small color="green">noise_control_off</v-icon>
                                            <v-icon color="red" @click="del_user(item)">
                                                delete
                                            </v-icon>
                                        </v-row>

                                    </div>
                                    <div v-else>
                                        <v-row class="d-flex">
                                            <v-icon small color="grey">noise_control_off</v-icon>
                                            <v-icon color="red" @click="del_user(item)">
                                                delete
                                            </v-icon>
                                        </v-row>
                                    </div>

                                </template>

                                <template v-slot:item.action="{ item }">

                                    <div v-if="item.action == false"></div>
                                    <div v-else>
                                        <v-btn tile @click="goAzq(item)" color="bg-orange" x-small height="32" class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                                            <span>ทดสอบ<br>AZQ</span>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                       
                        </v-card>
                        <div class="text-center pt-2">
                            <v-pagination v-model="page" :length="pageCount"></v-pagination>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                <v-icon left> control_point </v-icon>
                เพิ่มการนัดหมาย
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    <v-icon left> control_point </v-icon>
                    เพิ่มการนัดหมาย
                </v-btn>
            </v-row>
        </v-footer> -->
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import {
    LIFFID
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        cf_dialog: false,
        patient_list: [],
        active_waiting: false,
        title: '',
        status: '',
        search: '',
        attrs: "",
        on: "",
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        bg_sound: new Audio(require('@/assets/img/checkmong/azq/audio/bg_sound.mp3')),
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

        headers: [{
                text: "ลำดับ",
                value: "id",
            },
            {
                text: "ชื่อ-นามสกุล",
                value: "patient_name",
            },
            {
                text: "",
                value: "line_status",
            },
            {
                text: "",
                value: "action",
                sortable: false
            },
        ],
        // patient_list: [{
        //         id: "1",
        //         patient_name: "ชัชวาลย์ รัตนโสภา",
        //         line_status: true,
        //         action: false
        //     },
        //     {
        //         id: "2",
        //         patient_name: "ลลิตา รัตนโสภา",
        //         line_status: false,
        //         action: true
        //     },
        // ],
    }),
    async beforeCreate() {
        window.liff.ready
        // await window.liff.init({
        //     liffId: LIFFID
        // }).then(() => {
        //     //console.log('done window.liff.init')
        // });
    },
    mounted() {

        this.getPatientList()
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        del_user(val) {
            this.selected_row = val
            this.cf_dialog = true
        },
        async delRelation() {
            //console.log('his.selected_row.user_relation_id:',this.selected_row.user_relation_id)
            this.active_waiting = true
            this.title = 'กำลังลบข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/del_relation', {
                "user_relation_id": this.selected_row.user_relation_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    this.dialog_edit = false
                    this.dialog = false
                    // this.getBookingList()
                    this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },

        async getPatientList() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดต',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_patient_list', {
                "user_id": this.$store.state.login_detail.user_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.patient_list = response.data.patient_list;
                    //console.log('777777777777777')
                    //console.log(this.patient_list)
                } else {
                    this.active_waiting = false
                }

            })

        },
        update_status_wait(val) {
            this.active_waiting = val
        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog = true;
        },
        async getUserInfo(val) {
            // //console.log('this.$store.state.login_detail.user_id:',this.$store.state.login_detail.user_id)
            await Vue.axios.post(APIURL + '/get_user_info', {
                "user_id": val
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log('get_user_info',response)
                if (response.data) {
                    //console.log('get_user_info',response.data)
                    this.active_waiting = false
                    // this.$store.commit('setUserInfo',response.data);
                    this.$store.commit('setQrPatientId', (response.data.user_id).slice(0, 7))
                    this.$store.commit('setQrPatientInfo', response.data)
                    // this.$router.push('/view_patient_health_record_no_line')
                    this.$store.commit('setAzq_type', {
                        'azq_type': 'azq_public'
                    });
                    this.$router.push('/azq_provider')
                    // this.province = response.data.province
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                    // this.$router.push('/home_checkmong')
                } else {
                    this.active_waiting = false

                }

            })

        },
        goAzq(val) {
            // this.$router.push('/azq') 
            //console.log(val)
            this.setBgSound()
            this.getUserInfo(val.user_id)
        },
        toggleApi() {
            //console.log('77777toggleApi')
            this.$fullscreen.toggle()
            this.hidden = !this.hidden
        },
        setBgSound() {
            //     const azq_ans_temp = this.$store.state.azq_ans
            //     this.$store.commit('setAzqAns', {
            //     ...azq_ans_temp,
            //     'bg_sound': this.bg_sound,
            //     'full_name':this.$store.state.qr_patient_info.fullname,
            //     'phone_number':this.$store.state.qr_patient_info.phone_number,
            //     'age':this.$store.state.qr_patient_info.age,
            //     'user_id':this.$store.state.qr_patient_info.user_id

            // });

            // // this.azq_ans_temp = this.$store.state.azq_ans
            // //     this.azq_ans_temp.s_pdpa = this.pdpa_accept
            // //     this.azq_ans_temp.current_s = 's_pdpa'
            // //     //console.log(this.azq_ans_temp)
            // //     // //console.log(current_ans)
            // //     // this.play(this.bg_sound)
            // //     this.$store.commit('setAzqAns', {
            // //         ...this.azq_ans_temp
            // //     });
            // this.play(this.$store.state.azq_ans.bg_sound)

        },
        play(audio) {
            audio.isPlaying = true;
            if (typeof audio.loop == 'boolean') {
                audio.loop = true;
            } else {
                audio.addEventListener('ended', function () {
                    this.currentTime = 0;
                    this.play();
                }, false);
            }
            audio.play();
        },

        pause(audio) {
            audio.isPlaying = false;
            audio.pause();
            audio.currentTime = 0;
        },
    },
};
</script>

<style >

</style>
